.carouselImg{
    width: 430px;
    height: 430px;
    box-shadow: 0 0 7px 7px #6775c19e;  
    display: flex;
}
.card{
    padding: 20px;
  }
  /* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    
    .carouselImg{
        width: 310px;
        height: 310px;
        box-shadow: 0 0 7px 7px #6775c19e;
    } 
    .card{
        padding: 20px;
      }
}
