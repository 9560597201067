.background{
    background-color: rgba(242, 243, 249, 0.84);
    margin-top: -35px;
}
.lineOurServices{
    flex-grow: 1;
    height: 8px;
    background-color:#ea7f79ba;
    max-width: 100%;
    margin-left: 260px;
}

.lineWithTitle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.titleParagOurServices{
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 260px;
    margin-left: 20px;
    }

    /* RESPONSIVE DESIGN */
    @media all and (max-width:1750px){
        .titleParagOurServices{
            font-size: 35px;
            }
    }

@media all and (max-width:1440px){
    .background{
        background-color: rgba(242, 243, 249, 0.84);
        margin-top: -25px;
    }
    .lineOurServices{
        width: 600px;
        height: 4px;
        margin-left: 160px;
    }
    .titleParagOurServices{
        font-size: 35px;
        margin-right: 160px;
        }
}
@media all and (max-width:1024px){
    .lineOurServices{
        margin-left: 100px;
    }
    .titleParagOurServices{
        font-size: 30px;
        margin-right: 100px;
        }

}
@media all and (max-width:820px){
    .titleParagOurServices{
        font-size: 25px;
        font-weight: 600;
        }
}
@media all and (max-width:650px){
    .titleParagOurServices{
        font-weight: 500;
        margin-right: 50px;
        }
        .lineOurServices{
            margin-left: 50px;
        }
}
@media all and (max-width:390px){
    .titleParagOurServices{
        margin-right: 20px;
        }
        .lineOurServices{
            margin-left: 20px;
        }
}