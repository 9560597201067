.logo{
    width:170px;
}
.navTitle{
    color: white;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}
.navTitle:hover{
    text-decoration: underline;
    cursor: pointer;
}
.navbar{
    display: flex;
    /* align-items: center; */
    gap: 30px;
    margin-left: 40px;

}
.navbarContainer{
display: flex;
/* align-items: center; */
justify-content: space-between;
margin: 60px 180px;
}
.logoAndTitles{
    display: flex;
    align-items: flex-start;
    /* align-items: last baseline; */
    /* align-items: flex-start; */
}
.btn{
    width: 300px;
    height: 64px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    border: 4px solid white;
    background-color: transparent;
    cursor: pointer;

}
.btn:hover{
    border: 4px solid #2E3D9D;  
    background: #FFF;
}
.emaillink:hover{
    color: #2E3D9D;
}
a{
    text-decoration: none;
}
.hamIcon{
    font-size: 25px;
    color: #fff;
    display: none;
    cursor: pointer;
  }
  .hiddenSidebar{
    display: none;
  }
  /* Sidebar */
.sidebar{
    position: fixed;
    z-index: 6;
    right: 0;
    top: 0;
    height: 30%;
    width: 200px;
    padding: 3rem 0;
    background-color: #9da8f263;
    backdrop-filter: blur(10px);
    overflow: hidden;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  .closeSidebarContainer{
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
  .closeSidebar{
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
  }
  .closeSidebar:hover{
    color: #2E3D9D;
    cursor: pointer;
  }
  



/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .logo{
        width:120px;
    }
    .navTitle{
        font-size: 20px;
    }
    .navbar{
        gap: 20px;
    }
    .navbarContainer{
    margin: 46px 100px;
    }
    .btn{
        width: 210px;
        height: 46px;
        font-size: 20px;
        border: 3px solid white;
    }
    .btn:hover{
        border: 3px solid #2E3D9D;
    }
}
@media all and (max-width:900px){
  .navbarContainer{
    margin-left: 30px;
    margin-right: 30px;
  }
  .btn{
    width: 170px;
    height: 44px;
    font-size: 18px;
}
.logo{
  width:100px;
}
.navTitle{
  font-size: 18px;
}
}


@media all and (max-width:820px){
    .logo{
        width:100px;
    }
    .hamIcon{
        display: block;
      }
      .hiddenSidebar{
        display: block;
      }
      .navbar{
        display: none;
      }
      .blockbtn{
        display: none;
      }
      .navTitle{
        margin-left: 20px;
        margin-top: 20px;
      }
    
}
@media all and (max-width:650px){
    .navbarContainer{
        margin: 30px 50px;
        }
}
@media all and (max-width:390px){
  .navbarContainer{
    margin: 20px 20px;
    }
}
