.background{
    background-color: rgba(242, 243, 249, 0.84);
}
.titleParag{
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 260px;
margin-right: 20px;
}
.lineAboutUs{
    flex-grow: 1;
    height: 8px;
    background-color:#ea7f79ba;
    max-width: 100%;
    margin-right: 260px;
}
.lineWithTitleAboutUs{
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: flex-start;
    width: 100%;
}
.blueLetter{
    color: #2E3D9D;
    font-weight: 500;
}
.hiddenText{
line-height: 1.6;
text-align: justify;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 20px;
}
.absatz{
    margin-left: 40px;
    line-height: 1.6;
}
.text{
text-align: justify;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 260px;
margin-left: 260px;
line-height: 1.6;
}
.textToTheRight{
text-align: justify;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 260px;
margin-left: 50px;
line-height: 1.6;
}
.aboutUsPhoto{
    margin-left: 260px;
    width: 400px;
    height: 400px;
}
.textWithPic{
    display: flex;
    align-items: center;
}
.btnSecondary{
    width: 300px;
    height: 64px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    border: 4px solid #2E3D9D;  
    background: #FFF;
    color: #2E3D9D;
    cursor: pointer;
}
.btnSecondary:hover{
    border: none;
    color: white;
    background-color: #2E3D9D;
}
.readmoreComponent{
    display: flex;
    align-items: center;
    margin-left: 260px;
    margin-right: 260px;
}

.linebtnRight{
    flex-grow: 1;
    height: 8px;
    background-color: #ea7f79ba;
    max-width: 100%;
}
.linebtnLeft{
    flex-grow: 1;
    height: 8px;
    background-color: #ea7f79ba;
    max-width: 100%;
}
.visible820{
    display: none;
}

/* RESPONSIVE DESIGN */
@media all and (max-width:1800px){
    .titleParag{
        font-size: 35px;
        }
        .hiddenText{
            line-height: 1.3;
            }
            .text{
                line-height: 1.3;
            }
            .textToTheRight{
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
            margin-right: 260px;
            margin-left: 20px;
            line-height: 1.3;
            }
            .hiddenText{
                display: block;
            }
            .hidden{
                display: none;
            }
}
@media all and (max-width:1680px){
    .aboutUsPhoto{
        width: 480px;
        height: 480px;
        margin-top: -160px;
    }
}

@media all and (max-width:1440px){
    .background{
        margin-top: 0px;
        padding-top: 40px;
    }
    .titleParag{
    margin-left: 160px;
    font-size: 35px;
    }
    .hiddenText{
    font-size: 20px;
    line-height: 1.3;
    }
    .text{
        font-size: 20px;
        margin-right: 160px;
        margin-left: 160px;
        line-height: 1.3;
    }
    .lineAboutUs{
        height: 4px;
        margin-right: 160px;
    }
    .textToTheRight{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    margin-right: 160px;
    margin-left: 20px;
    line-height: 1.3;
    }
    .aboutUsPhoto{
        margin-left: 160px;
        width: 310px;
        height: 310px;
        margin-top: 0;
    }
    .hiddenText{
        display: block;
    }
    .hidden{
        display: none;
    }
    .btnSecondary{
        width: 210px;
        height: 40px;
        font-size: 20px;
        border: 2px solid #2E3D9D;  
    }
    .btn:hover{
        border: 2px solid white;
    }
    .readmoreComponent{
        margin-left: 160px;
        margin-right: 160px;
    }
.linebtnRight, .linebtnLeft{
        height: 4px;
    }
}
@media all and (max-width:1024px){
    .background{
        padding-top: 30px;
    }
    .titleParag{
    margin-left: 100px;
    font-size: 30px;
    }
    .text{
    margin-right: 100px;
    margin-left: 100px;
    }
    .lineAboutUs{
        margin-right: 100px;
    }
    .textToTheRight{
    margin-right: 100px;
    margin-left: 15px;
    }
    .aboutUsPhoto{
        margin-left: 100px;
        width: 360px;
        height: 360px;
        margin-top: 30px;
    }
    .btnSecondary{
        width: 210px;
        height: 46px;
        font-size: 20px; 
    }
    .readmoreComponent{
        margin-left: 100px;
        margin-right: 100px;
    }
    .textWithPic{
        align-items: flex-start;
        }
}
@media all and (max-width:900px){
    .btnSecondary{
      width: 180px;
  }
  .hiddenText{
    display: none;
}
.visible820{
    display: block;
}
.aboutUsPhoto{
    width: 190px;
    height: 190px;
}
  }

@media all and (max-width:820px){
    .titleParag{
font-size: 25px;
font-weight: 600;
}
.space{
    margin-top: 20px;
}
.hiddenText{
    display: none;
}
.visible820{
    display: block;
}
.text{
font-size: 18px;
}
.textToTheRight{
font-size: 18px;
}

.aboutUsPhoto{
    width: 160px;
    height: 160px;
}
.btnSecondary{
    width: 160px;
    height: 40px;
    font-size: 18px;
}

}
@media all and (max-width:590px){
    .titleParag{
        font-weight: 500;
        margin-left: 50px;
        }
        .lineAboutUs{
            margin-right: 50px;
        }
    .btnSecondary{
        width: 130px;
        height: 34px;
        font-size: 14px;
    }
    .aboutUsPhoto{
        width: 270px;
        height: 270px;
        display: flex;
        justify-content: center;
        margin-left: 0;
    }
    .textWithPic{
        flex-direction: column;
        align-items: center;
    }
    .textToTheRight{
        margin-right: 50px;
        margin-left: 50px;
        }
.text{
    margin-left: 50px;
    margin-right: 50px;
}
.readmoreComponent{
    margin-left: 50px;
    margin-right: 50px;
}
}
@media all and (max-width:390px){
    .titleParag{
        margin-left: 20px;
        }
        .lineAboutUs{
            margin-right: 20px;
        }
    .textToTheRight{
        margin-right: 20px;
        margin-left: 20px;
        }
.text{
    margin-left: 20px;
    margin-right: 20px;
}
.readmoreComponent{
    margin-left: 20px;
    margin-right: 20px;
}
}

