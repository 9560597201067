.titleToEnd{
    display: flex;
    justify-content: flex-end;
    margin-right: 260px;
}
.iconTitle{
color: #2E3D9D;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.iconText{
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -30px;
    line-height: 1.6;
}
.bigContainerwithIcons{
    display: flex;
    margin-left: 260px;
    margin-right: 260px;
    justify-content: space-between;
}
.smallContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pContainer{
    width: 400px;
}
.icon{
    width: 100px;
}

/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .titleToEnd{
        margin-right: 160px;
    }
    .iconTitle{
    font-size: 25px;
    }
    .iconText{
        color: #000;
        text-align: center;
        font-size: 20px;
        margin-top: -10px;
        line-height: 1.3;
    }
    .bigContainerwithIcons{
        display: flex;
        margin-left: 160px;
        margin-right: 160px;
    }
    .smallContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pContainer{
        width: 300px;
    }
    .icon{
        width: 50px;
    }
}

@media all and (max-width:1024px){
    .titleToEnd{
        margin-right: 100px;
    }
    .iconTitle{
    font-size: 20px;
    }
    .bigContainerwithIcons{
        margin-left: 100px;
        margin-right: 100px;
    }
    .pContainer{
        width: 200px;
    }
    
}
@media all and (max-width:820px){
    .pContainer{
        width: 200px;
    }
    .iconText{
        font-size: 16px;
    }
    .pContainer{
        width: 170px;
    }
}
@media all and (max-width:650px){
    .titleToEnd{
        margin-right: 50px;
    }
    .bigContainerwithIcons{
        margin-left: 50px;
        margin-right: 50px;
        flex-direction: column;
    }
    .pContainer{
        width: auto;
    }
    .icon{
        width: 45px;
    }
}
@media all and (max-width:390px){
    .titleToEnd{
        margin-right: 20px;
    }
}
@media all and (max-width:360px){
    .titleToEnd{
        margin-right: 20px;
    }
    .bigContainerwithIcons{
        margin-left: 20px;
        margin-right: 20px;
    }
}