.headerPictureMain {
    background: url('../photos/banerRotatedTwo.jpg');
    padding-top: 290px;
    background-size: cover, cover;
    background-position: right, center;
    height: 900px;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    z-index: -1;
  }
  @media all and (max-width: 1440px) {
    .headerPictureMain {
    padding-top: 0px;
}}

  @media all and (max-width: 890px) {
    .headerPictureMain {
        background-position: 80% center;
        height: 700px;
    }
}

  
  @media (max-width: 650px) {
    .headerPictureMain {
      background:linear-gradient(to top, #2e3d9dc8 30%, transparent 75%) 0 / 50% 70% no-repeat,url('../photos/banersmall.jpg');
      background-size: cover, cover;
      background-position: right, center;
      height: 900px;
    }
  }
  @media (max-width: 390px) {
    .headerPictureMain {
      padding-top: 70px;
    }
  }
  /* linear-gradient(to right, #2E3D9D 15%, transparent 100%) 0 / 50% 100% no-repeat, */
  