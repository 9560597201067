.headerStyle {
    position: relative;
    width: 100%;
    height:800px;
    overflow: hidden;
}

.headerBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.headerContext {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.title {
    color: white;
    font-weight: 700;
    font-size: 130px;
    margin-left: 260px;
    line-height: 1;
    margin-bottom: 0;
}

.andInc {
    font-size: 65px;
}

.undertext {
    color: white;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 3.5px;
    margin-left: 260px;
    margin-top: 0;
}
.floatingText{
font-size: 25px;
font-weight: 700;
line-height: normal;
letter-spacing: 1.5px;
margin-top: 50px;
}

.One{
    /* color: #B8BDE0; */
    color: rgba(255, 255, 255, 0.82);
    margin-left: 730px;
}
.Two{
    /* color: #9ba4e3; */
    color: rgba(255, 255, 255, 0.82);
    margin-left: 550px;
}

.Three{
    /* color: #ECEDF7; */
    color: rgba(255, 255, 255, 0.82);
    margin-left: 800px;
}
.test{
width: 100px;
height: 100px;
position: absolute;
margin-left: -300px;
margin-top: -170px;
}
.particlesHeader{
    width: 1150px;
}
/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .headerStyle {
        height: 650px;
    }
    .title {
        font-size: 100px;
        margin-left: 200px;
    }
    .andInc {
        font-size: 50px;
    }
    .undertext {
        font-size: 23px;
        margin-left: 200px;
    }
    .floatingText{
        font-size: 20px;
        font-weight: 600;
        margin-top: 70px;
        }
        
        .One{
            margin-left: 500px;
        }
        .Two{
            margin-left: 400px;
        }
        
        .Three{
            margin-left:570px;
        }
        .particlesHeader{
            width: 800px;
        }
    

}
@media all and (max-width:1024px){
    .title {
        margin-left: 100px;
    }
    .undertext {
        margin-left: 100px;
    }
  }

@media all and (max-width:820px){
    .headerStyle {
        height:560px;
    }
    .title {
        font-weight: 600;
        font-size: 100px;
        margin-left: 50px;
        line-height: 0.5;
    }
    .andInc {
        font-size: 50px;
    }
    
    .undertext {
        font-size: 22px;
        margin-left: 50px;
    }
    .floatingText{
    font-size: 20px;
    font-weight: 500;
    margin-top: 35px;
    }
    
    .One{
        margin-left: 200px;
    }
    .Two{
        margin-left: 230px;
    }
    
    .Three{
        margin-left: 280px;
    }
    .particlesHeader{
        width: 800px;
    }

}
@media all and (max-width:650px){
    .headerStyle {
        height:800px;
    }
    .title {
        margin-left: 50px;
        margin-top: 220px;
    }
    .undertext {
        margin-left: 50px;
    }
    .floatingText{
        margin-top: 120px;
        font-size: 18px;
        text-align: right;
        margin-right: 50px;
        }
        .One{
            margin-left: 0px;
        }
        .Two{
            margin-left: 0px;
        }
        
        .Three{
            margin-left: 0px;
        }
    .test{
        margin-top: -120px;
        }
        .particlesHeader{
            width: 500px;
            margin-left: 200px;
            margin-top: 150px;
        }
}
@media all and (max-width:500px){
    .title {
        font-size: 80px;
    }
    .andInc {
        font-size: 40px;
    }
    .undertext {
        font-size: 16px;
    }
    .floatingText{
    margin-top: 120px;
    font-size: 16px;
    text-align: right;
    margin-right: 50px;
    }
    .One{
        margin-left: 0px;
    }
    .Two{
        margin-left: 0px;
    }
    
    .Three{
        margin-left: 0px;
    }
    .test{
        margin-top: -120px;
        }
        .particlesHeader{
            width: 340px;
        }
}
@media all and (max-width:390px){
    .title {
        margin-left: 20px;
        margin-top: 100px;
    }
    .undertext {
        margin-left: 20px;
    }
    .floatingText{
        text-align: left;
        margin-left: 20px;
        }
        .particlesHeader{
            width: 300px;
            margin-top: 240px;
        }
}
@media all and (max-width:360px){
    .headerStyle {
        height:700px;
    }
    .undertext {
        margin-left: 20px;
    }
    .title {
        /* font-size: 60px;
        line-height: 0; */
        margin-left: 20px;
        margin-top: 80px;
    }
    .floatingText{
    margin-top: 100px;
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
    }
    .test{
        margin-top: -120px;
        }
        .particlesHeader{
            width: 300px;
            margin-top: 200px;
        }
}
@media all and (max-width:335px){
    .undertext {
        margin-left: 14px;
    }
    .title {
        font-size: 60px;
    }
    .andInc {
        font-size: 30px;
    }
    .undertext {
        font-size: 10px;
        margin-left: 20px;
    }
    .floatingText{
    margin-top: 60px;
    font-size: 12px;
    }
}