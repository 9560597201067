.tmobile-svg {
    width: 310px;  /* 310 / 2 */
    height: 57px;  /* 57 / 2 */
  }
  .atandt-svg{
    width: 240px;
    height: 135px;
  }
  .verizon-svg{
    width: 240px;
    height: 72px;
  }
  .dish-svg{
    width: 150px;
    height: 70px;
  }

      /* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .tmobile-svg {
        width: 155px;
        height: 28.5px; 
      }
      .atandt-svg{
        width: 120px;
        height: 67.5px;
      }
      .verizon-svg{
        width: 120px;
        height: 36px;
      }
      .dish-svg{
        width: 75px;
        height: 35px;
      }

}
@media all and (max-width:1280px){


}
@media all and (max-width:1024px){

}
@media all and (max-width:820px){

}
@media all and (max-width:590px){
  .tmobile-svg {
    width: 140px;
    height: 13.5px; 
  }
  .atandt-svg{
    width: 91px;
    height: 38.5px;
  }
  .verizon-svg{
    width: 102px;
    height: 18px;
  }
  .dish-svg{
    width: 60px;
    height: 18px;
  }
}
@media all and (max-width:340px){

}