.bigContainerRepair{
    background-color: #E2E4F2;
    padding-top: 60px;
    padding-bottom: 80px;
    position: relative; 
}
.bigContainerRepair::after {
    content: ""; 
    position: absolute;
    bottom: 0; 
    right: 0; 
    width: 50%;
    border-bottom: 8px solid #2E3D9D; 
    height: 0; 
  }
.serviceTitle{
text-align: center;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 900px;
}
.iconAndTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textAndImageRepair{
    display: flex;
    justify-content: space-between;
    margin-left: 260px;
    margin-right: 260px;
}
.serviceName{
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: normal;
list-style-type: none;
padding-left: 0;

}
.textRepair{
    width: 700px;
}

.liLine{
    position: relative;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px; 
    margin-bottom: 10px;
}
.liLine::before {
    content: ""; 
    position: absolute;
    left: 0; 
    top: 18px; 
    transform: translateY(-50%); 
    height: 2px; 
    width: 15px; 
    background-color: black; 
  }
  .staticParticles {
    position: absolute;
    top: 50%; 
    right: 0; 
    transform: translateY(-50%); 
    width: 700px;
    z-index: 2; 
}

.staticBlue {
    position: absolute;
    top: 50%; 
    right: 0;
    transform: translateY(-50%);
    width: 700px;
    z-index: 3; 
}


/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .bigContainerRepair{
        padding-top: 30px;
        padding-bottom: 60px;
    }
    .bigContainerRepair::after {
        border-bottom: 4px solid #2E3D9D; 
      }
    .serviceTitle{
    font-size: 20px;
    width: 900px;
    }
    .textAndImageRepair{
        margin-left: 160px;
        margin-right: 160px;
    }
    .serviceName{
    font-size: 20px;
    }
    .textRepair{
        width: 450px;
    } 
    .staticBlue{
        width: 400px;
      }
}
@media all and (max-width:1280px){

}
@media all and (max-width:1024px){
    .textAndImageRepair{
        margin-left: 100px;
        margin-right: 100px;
    }
}


@media all and (max-width:820px){
    .staticParticles {
        width: 300px;
    }
    
    .staticBlue {
        width: 300px;
    }
}
@media all and (max-width:650px){
    .staticParticles {
        width: 200px;
        top: 70%; 
        right: 0;
        transform: translateY(-50%);
    }
    
    .staticBlue {
        width: 200px;
        top: 70%; 
        right: 0;
        transform: translateY(-50%);
    }
    .textAndImageRepair{
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media all and (max-width:360px){
    .textAndImageRepair{
        margin-left: 20px;
        margin-right: 20px;
    }
}