.footer{
    background-color: #2E3D9D;
    width: auto;
    height: 400px;
    display: flex;
    justify-content: space-around;
    padding-top: 100px;
}
.textfooter{
    line-height: 2;
    color: #FFF;
    text-align: justify;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btn{
    width: 300px;
    height: 64px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    border: 4px solid white;
    background-color: transparent;
    cursor: pointer;
}
.btn:hover{
    border: 4px solid #2E3D9D;  
    background: #FFF;
    color: #2E3D9D;
}
.emaillink{
    text-decoration: none;
    color: white;
}
.emaillink:hover{
    color: #2E3D9D;
}

/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .footer{
        height: 230px;
        padding-top: 40px;

    }
    .textfooter{
        line-height: 1.5;
        font-size: 20px;
        font-weight: 400;
    }
    .logo{
        width:120px;
    }
    .btn{
        width: 210px;
        height: 40px;
        font-size: 20px;
        border: 3px solid white;
        margin-bottom: 8px;
    }
    .btn:hover{
        border: 3px solid #2E3D9D;
    }
}

@media all and (max-width:900px){
    .btn{
      width: 170px;
      height: 44px;
      font-size: 18px;
  }
  .logo{
    width:100px;
  }
  }
@media all and (max-width:820px){
    .logo{
        width:120px;
    }
    .footer{
        height: 240px;
        padding-top: 40px;
    }
    .textfooter{
        line-height: 1;
        font-size: 20px;
        font-weight: 400;
    }
    .btn{
        width: 160px;
        height: 40px;
        font-size: 18px;
        border: 2px solid white;
    }
    .btn:hover{
        border: 2px solid #2E3D9D;  
    }
}
@media all and (max-width:650px){
    .logo{
        width:100px;
    }
    .btn{
        width: 130px;
        height: 34px;
        font-size: 14px;
    }
    .footer{
        height: auto;
        flex-direction: column;
        padding-left: 50px;
        padding-top: 16px;
        padding-bottom: 20px;
    }
}
@media all and (max-width:390px){
    .footer{
        padding-left: 20px;
    }
}