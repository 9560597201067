.backgrPhotoAndText{
    display: flex;
    justify-content: flex-end;
}
.textMission{
    width:900px;
    color: #000;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 260px;
    } 
.backgrPhoto{
    position: absolute;
    margin-top: -300px;
    width: 500px;
}
.backgrPhContainer{
    display: flex;
    justify-content: flex-end;
   margin-right: 260px;
}  

/* RESPONSIVE DESIGN */

@media all and (max-width:1440px){
    .textMission{
        width:600px;
        font-size: 25px;
        font-weight: 400;
        margin-left: 160px;
        } 
    .backgrPhoto{
        position: absolute;
        margin-top: -200px;
        width: 300px;
    }
    .backgrPhContainer{
       margin-right: 160px;
    }  
}
@media all and (max-width:1024px){
    .backgrPhContainer{
       margin-right: 100px;
    }  

}
@media all and (max-width:820px){
    .textMission{
        font-size: 20px;
        width: 400px;
        } 
}
@media all and (max-width:650px){
    .textMission{
        font-size: 16px;
        margin-left: 50px;
        width: 300px;
        } 
        .backgrPhContainer{
            margin-right: 50px;
         }  

}

@media all and (max-width:390px){
    .backgrPhoto{
        margin-top: -100px;
        width: 200px;
        margin-right: -20px;
    }
    .textMission{
        margin-left: 20px;
        width: 260px;
        } 
}
@media all and (max-width:360px){
    .backgrPhoto{
        margin-top: -100px;
        width: 150px;
    }
}