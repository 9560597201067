.backToTopBtn{
    background-color: transparent;
    border: none;
    bottom: 40px;
    right: 30px;
    position: fixed;
    cursor: pointer;
    z-index: 3;
  }
  .chatBtn{
    background-color: transparent;
    border: none;
    bottom: 120px;
    right: 30px;
    position: fixed;
    cursor: pointer;
    z-index: 3;
  }
  .upImg{
  width: 60px;
  }

      /* RESPONSIVE DESIGN */
@media all and (max-width:1440px){
    .upImg{
        width: 40px;
        }
        .chatBtn{
        bottom: 90px;
        }
}
@media all and (max-width:1024px){
  .backToTopBtn{
    right: 15px;
  }
  .chatBtn{
    right: 15px;
  }

}
@media all and (max-width:820px){
  .upImg{
    width: 30px;
    }
}